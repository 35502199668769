import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const THEME = createTheme({
  palette: {
    primary: {
      main: "#6E22F7",
    },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          background: "#DEDEDE",
          borderRadius: "2px",
        },
        bar: {
          background: "linear-gradient(180deg, #5945F9 0%, #6E22F7 100%)",
          borderRadius: "2px",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "'Rubik', sans-serif",
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={THEME}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
