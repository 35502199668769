import { CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import useStyles from "./Loading-jss";

function Loading() {
  const classes = useStyles();
  return (
    <Stack direction="column" alignItems="center">
      <CircularProgress color="primary" />
      <Typography className={classes.txTitle}>Sedang Mengambil Data</Typography>
      <Typography className={classes.txDesc}>Mohon Tunggu</Typography>
    </Stack>
  );
}

export default Loading;
