import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  txTitle: {
    fontFamily: "'Rubik'",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "17px",
    textAlign: "center",
    background: "linear-gradient(180deg, #5945F9 0%, #6E22F7 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    marginBottom: "4px",
  },
  txDesc: {
    fontFamily: "'Rubik'",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "17px",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

export default useStyles;
