import { Grid } from "@mui/material";
import { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Loading from "./Components/Loading/Loading";

function App() {
  const Dashboard = lazy(() => import("./Pages/Dashboard/Dashboard"));
  const RaportWebinar = lazy(() =>
    import("./Pages/RaportWebinar/RaportWebinar")
  );
  const ResumeBelajar = lazy(() =>
    import("./Pages/ResumeBelajar/ResumeBelajar")
  );

  const ProgramSuccess = lazy(() =>
    import("./Pages/ProgramSuccess/ProgramSuccess")
  );

  const ParticipantRank = lazy(() =>
    import("./Pages/ParticipantRank/ParticipantRank")
  );
  const QuestionStatistic = lazy(() =>
    import("./Pages/QuestionStatistic/QuestionStatistic")
  );
  const QuestionStatistic2 = lazy(() =>
    import("./Pages/QuestionStatistic2/QuestionStatistic")
  );
  const Login = lazy(() => import("./Pages/Login/Login"));
  const QuizResume = lazy(() => import("./Pages/QuizResume/QuizResume"));
  const Message = lazy(() => import("./Pages/Message/Message"));
  const ParticipantRaport = lazy(() =>
    import("./Pages/ParticipantRaport/ParticipantRaport")
  );
  const ShareRaport = lazy(() => import("./Pages/ShareRaport/ShareRaport"));
  const StudyRank = lazy(() => import("./Pages/StudyRank/StudyRank"));
  const DigitalRaport = lazy(() => import("./Pages/DigitalRaport/DigitalRaport"));
  const TenantUsers = lazy(() => import("./Pages/TenantUsers/TenantUsers"));


  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const tenantData = JSON.parse(localStorage.getItem("tenantData"));
  useEffect(() => {
    if (
      (tenantData === null || !tenantData) &&
      window.location.pathname !== "login" &&
      (window.location.pathname.indexOf("/participant-raport/") < 0 &&
      window.location.pathname.indexOf("/digital-raport/") < 0)
    ) {
      navigate("/login");
    } else {
      setIsLoading(false);
    }
  }, [tenantData, navigate]);
  return (
    <Suspense
      fallback={
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100vh " }}
        >
          <Loading />
        </Grid>
      }
    >
      <Routes>
        <Route
          exact
          path="/"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <ResumeBelajar />
            )
          }
        />
        <Route
          exact
          path="/program-success"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <ProgramSuccess />
            )
          }
        />
        <Route
          exact
          path="/resume-webinar"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <Dashboard />
            )
          }
        />
        <Route
          exact
          path="/raport-webinar"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <RaportWebinar />
            )
          }
        />
        <Route
          exact
          path="/tenant-users"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <TenantUsers />
            )
          }
        />
        <Route
          exact
          path="/participant-rank"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <ParticipantRank />
            )
          }
        />
        <Route
          exact
          path="/question-statistic"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <QuestionStatistic />
            )
          }
        />
        <Route
          exact
          path="/question-statistic-2"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <QuestionStatistic2 />
            )
          }
        />
        <Route
          exact
          path="/quiz-resume"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <QuizResume />
            )
          }
        />
        <Route
          exact
          path="/message"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <Message />
            )
          }
        />
        <Route
          exact
          path="/participant-raport"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <ParticipantRaport />
            )
          }
        />
        <Route
          exact
          path="/study-rank"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <StudyRank />
            )
          }
        />
        <Route
          exact
          path="/participant-raport/:id/:email"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <ShareRaport />
            )
          }
        />
        <Route
          exact
          path="/participant-raport/:id/:email/:tenantId"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <ShareRaport />
            )
          }
        />
		    <Route
          exact
          path="/digital-raport/:id"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <DigitalRaport />
            )
          }
        />
        <Route
          exact
          path="/digital-raport/:id/:tenantId"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <DigitalRaport />
            )
          }
        />
        <Route
          exact
          path="/digital-raport/:id/:tenantId/:eventId"
          element={
            isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100vh " }}
              >
                <Loading />
              </Grid>
            ) : (
              <DigitalRaport />
            )
          }
        />
        <Route exact path="/login" element={<Login />} />
      </Routes>
    </Suspense>
  );
}

export default App;
